import React from "react"

import { graphql } from "gatsby"
import { Trans, useI18next } from "gatsby-plugin-react-i18next"
import { JesLayout } from "../components/jes-layout"
import { Bold, StandardParagraph } from "../components/blocks"

const DatenschutzPage = ({ data }) => {
  const { t } = useI18next()

  return (
    <JesLayout title={t("Datenzchutz")}>
      <h1> DISCLAIMER / HAFTUNGSHINWEISE</h1>
      <StandardParagraph>
        <Bold>Hinweise zu Inhalten</Bold>
      </StandardParagraph>
      <p>
        Die JES Kulturstiftung bemüht sich, die kostenlosen und auf ihrer
        Website frei zugänglichen Informationen und Inhalte mit größtmöglicher
        Sorgfalt zu erstellen. Für die Richtigkeit, Vollständigkeit und
        Aktualität dieser Informationen und Inhalte kann sie jedoch keine
        Haftung übernehmen und es besteht keine Verpflichtung, übermittelte oder
        gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
        forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
        Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
        Eine diesbezügliche Haftung kann jedoch erst ab dem Zeitpunkt der
        Kenntnis einer konkreten Rechtsverletzung bestehen.
      </p>
      <p>
        Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen
        Autors und nicht immer die Meinung der JES Kulturstiftung wieder.
      </p>
      <p>
        Allein durch den Aufruf der kostenlosen und frei zugänglichen
        Informationen und Inhalte kommt kein Vertragsverhältnis zwischen dem
        Nutzer und der JES Kulturstiftung zustande, insoweit fehlt es jedenfalls
        am Rechtsbindungswillen der JES Kulturstiftung.
      </p>
      <StandardParagraph>
        <Bold>Externe Links</Bold>
      </StandardParagraph>
      <p>
        Die JES Kulturstiftung ist als Inhaltsanbieter für die eigenen Inhalte,
        die sie zur Nutzung bereithält, nach den allgemeinen Gesetzen
        verantwortlich.
      </p>
      <p>
        Verknüpfungen zu Websites Dritter („externe Links“) unterliegen der
        Haftung der jeweiligen Betreiber. Die JES Kulturstiftung prüft bei der
        erstmaligen Verknüpfung der externen Links die fremden Inhalte
        dahingehend, ob etwaige Rechtsverstöße bestehen. Auf die aktuelle und
        zukünftige Gestaltung und auf die Inhalte der verknüpften Websites hat
        die JES Kulturstiftung keinen Einfluss; das Setzen eines externen Links
        bedeutet nicht, dass sich die JES Kulturstiftung die hinter den Links
        liegenden Inhalte und Informationen zu eigen macht. Eine ständige
        Kontrolle der externen Links erfolgt nicht. Bei Kenntnis von
        Rechtsverstößen werden jedoch, soweit technisch möglich und zumutbar,
        solche externen Links unverzüglich gelöscht.
      </p>
      <StandardParagraph>
        <Bold>Urheberrecht</Bold>
      </StandardParagraph>
      <p>
        Auf dieser Website veröffentlichte Inhalte und Werke unterliegen dem
        deutschen Urheberrecht. Jede danach nicht zulässige Vervielfältigung,
        Bearbeitung, Verbreitung und Verwertung bedürfen der schriftlichen
        Zustimmung der JES Kulturstiftung bzw. des jeweiligen Autors /
        Erstellers. Downloads und Kopien dieser Website sind nur für den
        privaten, nicht kommerziellen Gebrauch gestattet.
      </p>
      <p>
        Soweit die Inhalte auf dieser Seite nicht von der JES Kulturstiftung
        erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
        werden Inhalte Dritter als solche gekennzeichnet. Sollten dennoch
        Urheberrechtsverletzungen auftreten, wird die JES Kulturstiftung bei
        deren Bekanntwerden derartige Inhalte umgehend entfernen.
      </p>
      <StandardParagraph>
        <Bold>HINWEISE ZUR DATENVERARBEITUNG</Bold>
      </StandardParagraph>
      <p>
        Die JES Kulturstiftung nimmt den Schutz der persönlichen Daten der
        Nutzer ihrer Website sehr ernst und hält sich strikt an die Regeln der
        Datenschutzgesetze (DSGVO, Bundesdatenschutzgesetz sowie des
        Telemediengesetzes). Personenbezogene Daten werden auf dieser Webseite
        nur nach Maßgabe dieser Hinweise zur Datenverarbeitung erhoben und
        verarbeitet. In keinem Fall werden die erhobenen Daten verkauft oder aus
        anderen Gründen an Dritte weitergegeben. Diese Hinweise zur
        Datenverarbeitung gelten für die Nutzung der Webseite unter der Adresse{" "}
        <a
          style={{ fontSize: "inherit" }}
          href="https://www.jes-kulturstiftung.de"
        >
          www.jes-kulturstiftung.de
        </a>
        .
      </p>
      <br />
      <h3>A. ALLGEMEINES</h3>
      <StandardParagraph>
        <Bold>
          Name und Kontaktdaten des für die Verarbeitung Verantwortlichen
        </Bold>
      </StandardParagraph>
      <p>
        Diese Datenschutzhinweise gelten für die Datenverarbeitung durch die JES
        Kulturstiftung, vertreten durch die Vorstände Herrn Dr. Dr. Jochen
        Seifert und Frau Erika Seifert, Adolf-Münzer-Straße 9, 86919 Holzhausen
        / Ammersee, Telefon: +49 (0)8806 924115, E-Mail:{" "}
        <a
          style={{ fontSize: "inherit" }}
          href="mailto:post@jes-kulturstiftung.de"
        >
          post@jes-kulturstiftung.de
        </a>
      </p>
      <StandardParagraph>
        <Bold>Umfang der Verarbeitung personenbezogener Daten</Bold>
      </StandardParagraph>
      <p>
        Die JES Kulturstiftung erhebt und verwendet personenbezogene Daten der
        Website-Nutzer grund­sätzlich nur, soweit dies zur Bereitstellung einer
        funktionsfähigen Website sowie der dortigen Inhalte und Leistungen
        erforderlich ist. Die Erhebung und Verwendung personenbezogener Daten
        der Nutzer erfolgt nur nach Einwilligung des Nutzers, es sei denn, die
        vorherige Einholung einer Einwilligung aus tatsächlichen Gründen ist
        nicht möglich und die Verarbeitung der Daten ist durch gesetzliche
        Vorschriften gestattet.
      </p>
      <StandardParagraph>
        <Bold>
          Rechtsgrundlage für die Verarbeitung personenbezogener Daten
        </Bold>
      </StandardParagraph>
      <p>
        Soweit eine Einwilligung der betroffenen Person für die Verarbeitung
        ihrer personenbezogener Daten vorliegt, erfolgt die Verarbeitung auf der
        Rechtsgrundlage des Art.6 Abs.1 lit.a DSGVO.
      </p>
      <p>
        Bei der Verarbeitung personenbezogener Daten zur Erfüllung eines
        Vertrages, dessen Vertragspartei die betroffene Person ist, ist Art. 6
        Abs.1 lit.b DSGVO Rechtsgrundlage für die Verarbeitung der Daten. Dies
        gilt auch für Verarbeitungsvorgänge, die zur Durchführung
        vorvertraglicher Maßnahmen erforderlich sind.
      </p>
      <p>
        Ist eine Verarbeitung personenbezogener Daten zur Erfüllung einer
        rechtlichen Verpflichtung erforderlich, der die JES Kulturstiftung
        unterliegt, ist Art. 6 Abs.1 lit.c DSGVO Rechtsgrundlage für die
        Datenverarbeitung.
      </p>
      <p>
        Die Verarbeitung personenbezogener Daten erfolgt auf der Rechtsgrundlage
        von Art.6 Abs.1 lit.f DSGVO, wenn sie zur Wahrung eines berechtigten
        Interesses der JES Kulturstiftung oder eines Dritten erforderlich ist
        und die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
        erstgenannte Interesse nicht überwiegt.
      </p>
      <StandardParagraph>
        <Bold>Datenlöschung und Speicherdauer</Bold>
      </StandardParagraph>
      <p>
        Die personenbezogenen Daten der betroffenen Person werden gelöscht oder
        gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung
        kann darüber hinaus dann erfolgen, wenn dies durch den europäischen oder
        nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder
        sonstigen Vorschriften, denen die JES Kulturstiftung unterliegt,
        vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch
        dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist
        abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren
        Speicherung der Daten besteht.
      </p>
      <br />
      <h3>B. BEREITSTELLUNG DER WEBSITE UND ERSTELLUNG VON LOGFILES</h3>
      <StandardParagraph>
        <Bold>Beschreibung und Umfang der Datenverarbeitung</Bold>
      </StandardParagraph>
      <p>
        Bei jedem Aufruf der Website der JES Kulturstiftung erfasst das
        zugrundeliegende System automati­siert Daten und Informationen vom
        Computersystem des aufrufenden Rechners. Folgende Daten werden hierbei
        erhoben:
        <ul style={{ fontSize: "inherit" }}>
          <li style={{ fontSize: "inherit" }}>
            Informationen über den Browsertyp und die verwendete Version
          </li>
          <li style={{ fontSize: "inherit" }}>
            das Betriebssystem des Nutzers
          </li>
          <li style={{ fontSize: "inherit" }}>
            den Internet-Service-Provider des Nutzers
          </li>
          <li style={{ fontSize: "inherit" }}>die IP-Adresse des Nutzers</li>
          <li style={{ fontSize: "inherit" }}>
            Datum und Uhrzeit des Zugriffs
          </li>
          <li style={{ fontSize: "inherit" }}>
            Websites, von denen das System des Nutzers auf die Website der JES
            Kulturstiftung gelangt
          </li>
          <li style={{ fontSize: "inherit" }}>
            Websites, die vom System des Nutzers über die Website der JES
            Kulturstiftung aufgerufen werden
          </li>
        </ul>
        <p>
          Die Daten werden ebenfalls in den Logfiles des zugrundeliegenden
          Systems gespeichert. Eine Speicherung dieser Daten zusammen mit
          anderen personenbezogenen Daten des Nutzers findet nicht statt.
        </p>
      </p>
      <StandardParagraph>
        <Bold>
          Rechtsgrundlage für die Verarbeitung personenbezogener Daten
        </Bold>
      </StandardParagraph>
      <p>
        Rechtsgrundlage für die vorübergehende Speicherung der Daten und der
        Logfiles ist Art. 6 Abs. 1 lit. f DSGVO.
      </p>
      <StandardParagraph>
        <Bold>Zweck der Datenverarbeitung</Bold>
      </StandardParagraph>
      <p>
        Die vorübergehende Speicherung der IP-Adresse durch das System ist
        notwendig, um eine Auslieferung der Website an den Rechner des Nutzers
        zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer
        der Sitzung gespeichert bleiben. Die Speicherung in Logfiles erfolgt, um
        die Funktionsfähigkeit der Website sicherzustellen. Zudem dienen die
        Daten zur Optimierung der Website und zur Sicherstellung der Sicherheit
        der informationstechnischen Systeme. Eine Auswertung der Daten zu
        Marketingzwecken findet in diesem Zusammenhang nicht statt. In diesen
        Zwecken liegt auch das berechtigte Interesse der JES Kulturstiftung an
        der Datenverarbeitung nach Art.6 Abs.1 lit.f DSGVO.
      </p>
      <StandardParagraph>
        <Bold>Dauer der Speicherung</Bold>
      </StandardParagraph>
      <p>
        Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
        ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der
        Daten zur Bereitstellung der Website ist dies der Fall, wenn die
        jeweilige Sitzung beendet ist. Im Falle der Speicherung der Daten in
        Logfiles ist dies nach spätestens sieben Tagen der Fall. Eine
        darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die
        IP-Adressen der Nutzer gelöscht oder verfremdet, sodass eine Zuordnung
        des aufrufenden Clients nicht mehr möglich ist.
      </p>
      <StandardParagraph>
        <Bold>Widerspruchs- und Beseitigungsmöglichkeit</Bold>
      </StandardParagraph>
      <p>
        Die Erfassung der Daten zur Bereitstellung der Website und die
        Speicherung der Daten in Logfiles ist für den Betrieb der Internetseite
        zwingend erforderlich. Es besteht folglich seitens des Nutzers keine
        Widerspruchsmöglichkeit.
      </p>
      <br />
      <h3>C. COOKIES</h3>
      <StandardParagraph>
        <Bold>Beschreibung und Umfang der Datenverarbeitung</Bold>
      </StandardParagraph>
      <p>
        Um die Webseite für den Nutzer optimal zu gestalten, verwendet die JES
        Kulturstiftung Cookies, d.h. Textdateien, die im Internetbrowser bzw.
        vom Internetbrowser auf dem Computersystem des Nutzers gespeichert
        werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem
        Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält
        eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung
        des Browsers beim erneuten Aufrufen der Website ermöglicht. Die auf
        diese Weise erhobenen Daten der Nutzer werden durch technische
        Vorkehrungen pseudonymisiert. Daher ist eine Zuordnung der Daten zum
        aufrufenden Nutzer nicht mehr möglich. Die Daten werden nicht gemeinsam
        mit sonstigen personenbezogenen Daten der Nutzer gespeichert.
      </p>
      <StandardParagraph>
        <Bold>Rechtsgrundlage für die Datenverarbeitung</Bold>
      </StandardParagraph>
      <p>
        Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter
        Verwendung von dieser Art von Cookies ist Art. 6 Abs. 1 lit. f DSGVO.
      </p>
      <StandardParagraph>
        <Bold>Zweck der Datenverarbeitung</Bold>
      </StandardParagraph>
      <p>
        Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung
        von Websites für die Nutzer zu vereinfachen. Einige Funktionen der
        WEbsite können ohne den Einsatz von Cookies nicht angeboten werden. Für
        diese ist es erforderlich, dass der Browser auch nach einem
        Seitenwechsel wiedererkannt wird. In diesen Zwecken liegt auch das
        berechtigte Interesse der JES Kulturstiftung in der Verarbeitung der
        personenbezogenen Daten nach Art. 6 Abs. 1 lit. f DSGVO.
      </p>
      <StandardParagraph>
        <Bold>
          {" "}
          Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit
        </Bold>
      </StandardParagraph>
      <p>
        Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an
        die Website der JES Kulturstiftung übermittelt. Daher hat der Nutzer
        auch die volle Kontrolle über die Verwendung von Cookies. Durch eine
        Änderung der Einstellungen im Internetbrowser kann der Nutzer die
        Übertragung von Cookies deaktivieren oder einschränken. Bereits
        gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch
        automatisiert erfolgen. Werden Cookies für diese Website deaktiviert,
        können möglicherweise nicht mehr alle Funktionen der Website
        vollumfänglich genutzt werden.
      </p>
      <br />
      <h3>D. NEWSLETTER</h3>
      <StandardParagraph>
        <Bold>Beschreibung und Umfang der Datenverarbeitung</Bold>
      </StandardParagraph>
      <p>
        Der Newsletter-Versand erfolgt aufgrund einer Anmeldung des Nutzers auf
        der Website der JES Kulturstiftung oder weil die JES Kulturstiftung eine
        E-Mail-Adresse mit der Aufforderung zum Newsletter-Versand erhalten hat.
        Auf der Website besteht die Möglichkeit, kostenfreie Newsletter zu
        abonnieren. Dabei werden bei der Anmeldung zum Newsletter die Daten aus
        der Eingabemaske an die JES Kulturstiftung übermittelt. Übermittelt wird
        an dieser Stelle die E-Mail-Adresse, der Name, die freiwilligen Angaben,
        die IP-Adresse des aufrufenden Rechners sowie Datum und Uhrzeit der
        Registrierung. Für die Verarbeitung der Daten wird im Rahmen des
        Anmeldevorgangs die Einwilligung des Betroffenen eingeholt und auf diese
        Hinweise zur Datenverarbeitung verwiesen.
      </p>
      <StandardParagraph>
        <Bold>Rechtsgrundlage für die Datenverarbeitung</Bold>
      </StandardParagraph>
      <p>
        Der Newsletter-Versand erfolgt aufgrund der Anmeldung des Nutzers auf
        der Website. Rechts­grundlage für die Verarbeitung der Daten nach
        Anmeldung zum Newsletters und Einwilligung des Nutzers ist Art. 6 Abs. 1
        lit. a DSGVO.
      </p>
      <StandardParagraph>
        <Bold>Zweck der Datenverarbeitung</Bold>
      </StandardParagraph>
      <p>
        Die Erhebung der E-Mail-Adresse des Nutzers dient dazu, den Newsletter
        zuzustellen. Der Newsletter-Versand erfolgt aufgrund von Anmeldung des
        Nutzers auf der Website. Die Erhebung sonstiger personenbezogener Daten
        im Rahmen des Anmeldevorgangs dient dazu, einen Missbrauch der Dienste
        oder der verwendeten E-Mail-Adresse zu verhindern und den Newsletter zu
        personalisieren. Etwaige freiwilligen Angaben werden genutzt, um besser
        zu verstehen, wie die Nutzer auf die JES Kulturstiftung aufmerksam
        werden.
      </p>
      <StandardParagraph>
        <Bold>Weitergabe der Daten an Dritte</Bold>
      </StandardParagraph>
      <p>
        Die JES Kulturstiftung hat mit dem Versand des Newsletters Mailchimp
        beauftragt. Mit Mailchimp besteht ein Auftragsverarbeitungsvertrag nach
        Art.26 DSGVO, der die Sicherheit der personenbezogenen Daten der
        betroffenen Personen gewährleistet.
      </p>
      <StandardParagraph>
        <Bold>Dauer der Speicherung der Daten</Bold>
      </StandardParagraph>
      <p>
        Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
        ihrer Erhebung nicht mehr erforderlich sind. Die E-Mail-Adresse des
        Nutzers wird demnach solange gespeichert, wie das Abonnement des
        Newsletters aktiv ist. Die sonstigen im Rahmen des Anmeldevorgangs
        erhobenen personenbezogenen Daten werden in der Regel nach einer Frist
        von sieben Tagen gelöscht.
      </p>
      <StandardParagraph>
        <Bold>Widerspruchs- und Beseitigungsmöglichkeit</Bold>
      </StandardParagraph>
      <p>
        Das Abonnement des Newsletters kann durch den betroffenen Nutzer
        jederzeit gekündigt werden. Zu diesem Zweck findet sich in jedem
        Newsletter ein entsprechender Link.
      </p>
      <br />
      <h3>E. RECHTE DER BETROFFENEN PERSON</h3>
      <p>
        Betroffene im Sinne der DSGVO, d.h. Personen, deren personenbezogenen
        Daten von der JES Kultur­stiftung verarbeitet werden, haben gegenüber
        der JES Kulturstiftung folgende Rechte:
      </p>
      <StandardParagraph>
        <Bold>Widerrufsrecht</Bold>
      </StandardParagraph>
      <p>
        Der Betroffene hat das Recht, soweit besondere personenbezogenene Daten
        auf Grundlage seiner Einwilligung verarbeitet werden, diese Einwilligung
        gemäß Art. 7 Abs. 3 DSGVO jederzeit gegenüber der JES Kulturstiftung zu
        widerrufen. Zur Wahrnehmung dieses Widerrufsrechts kann sich der
        Betroffene an JES Kulturstiftung wenden. Der Widerruf hat zur Folge,
        dass die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die
        Zukunft nicht mehr fortgeführt werden darf.
      </p>
      <StandardParagraph>
        <Bold>Auskunftsrecht</Bold>
      </StandardParagraph>
      <p>
        Der Betroffene hat das Recht, gemäß Art. 15 DSGVO Auskunft über seine
        von der JES Kulturstiftung verarbeiteten personenbezogenen Daten zu
        verlangen. Insbesondere kann er Auskunft über die Verarbeitungszwecke,
        die Kategorie der personenbezogenen Daten, die Kategorien von
        Empfängern, gegenüber denen seine Daten offengelegt wurden oder werden,
        die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung,
        Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen
        eines Beschwerderechts, die Herkunft seiner Daten, sofern diese nicht
        bei der JES Kulturstiftung erhoben wurden, sowie über das Bestehen einer
        automatisierten Entscheidungsfindung einschließlich Profiling und ggf.
        aussagekräftigen Informationen zu deren Einzelheiten verlangen.
      </p>
      <StandardParagraph>
        <Bold>Berichtigungsrecht</Bold>
      </StandardParagraph>
      <p>
        Der Betroffene kann gemäß Art. 16 DSGVO unverzüglich die Berichtigung
        unrichtiger oder Vervollständigung seiner bei der JES Kulturstiftung
        gespeicherten personenbezogenen Daten verlangen.
      </p>
      <StandardParagraph>
        <Bold>Löschungsrecht</Bold>
      </StandardParagraph>
      <p>
        Der Betroffene kann gemäß Art. 17 DSGVO die Löschung seiner bei der JES
        Kulturstiftung gespeicherten personenbezogenen Daten verlangen, soweit
        nicht die Verarbeitung zur Ausübung des Rechts auf freie
        Meinungsäußerung und Information, zur Erfüllung einer rechtlichen
        Verpflichtung, aus Gründen des öffentlichen Interesses oder zur
        Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
        erforderlich ist.
      </p>
      <StandardParagraph>
        <Bold>Recht auf Einschränkung der Datenverarbeitung.</Bold>
      </StandardParagraph>
      <p>
        Der Betroffene kann gemäß Art. 18 DSGVO die Einschränkung der
        Verarbeitung seiner personenbezogenen Daten verlangen, soweit die
        Richtigkeit der Daten von ihm bestritten wird, die Verarbeitung
        unrechtmäßig ist, er aber deren Löschung ablehnt und die JES
        Kulturstiftung die Daten nicht mehr benötigt, er jedoch diese zur
        Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigt
        oder er gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt
        hat.
      </p>
      <StandardParagraph>
        <Bold>auf Übertragung von Daten</Bold>
      </StandardParagraph>
      <p>
        Der Betroffene hat das Recht, gemäß Art. 20 DSGVO seine
        personenbezogenen Daten, die er der JES Kulturstiftung bereitgestellt
        hat, in einem strukturierten, gängigen und maschinenlesebaren Format zu
        erhalten oder die Übermittlung an einen anderen Verantwortlichen zu
        verlangen.
      </p>
      <StandardParagraph>
        <Bold>Recht zur Beschwerde</Bold>
      </StandardParagraph>
      <p>
        Der Betroffene hat das Recht, sich gemäß Art. 77 DSGVO bei einer
        Aufsichtsbehörde zu beschweren. In der Regel kann er sich hierfür an die
        Aufsichtsbehörde seines üblichen Aufenthaltsortes oder Arbeitsplatzes
        oder des Sitzes der JES Kulturstiftung wenden.
      </p>
      <StandardParagraph>
        <Bold>Widerspruchsrecht</Bold>
      </StandardParagraph>
      <p>
        Sofern die personenbezogenen Daten des Betroffenen auf Grundlage von
        berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO
        verarbeitet werden, hat er das Recht, gemäß Art. 21 DSGVO Widerspruch
        gegen die Verarbeitung seiner personenbezogenen Daten einzulegen, soweit
        dafür Gründe vorliegen, die sich aus seiner besonderen Situation
        ergeben. Um von diesem Widerspruchsrecht Gebrauch zu machen, genügt eine
        E-Mail an{" "}
        <a
          style={{ fontSize: "inherit" }}
          href="mailto:post@jes-kulturstiftung.de"
        >
          post@jes-kulturstiftung.de
        </a>
        .
      </p>
      <q>Status: 22.01.2021</q>
    </JesLayout>
  )
}

export default DatenschutzPage

export const query = graphql`
  query DatenschutzPageQuery($language: String!) {
    markdownRemark(frontmatter: { meta: { eq: "impressum" } }) {
      html
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
